<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-4 px-2>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Item Wise Report
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex
            xs12
            sm3
            md2
            text-right
            pa-2
            style="font-family: poppinsregular"
          >
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="getData(), (menu1 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex
            xs12
            sm3
            md2
            text-right
            pa-2
            style="font-family: poppinsregular"
          >
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="getData(), (menu2 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md2 lg1 xl1 text-center pa-2>
            <v-btn color="#30B868">
              <a
                target="_blank"
                :href="
                  ipURL +
                  '/order/itemWise/pdf?fromDate=' +
                  fromDate +
                  '&toDate=' +
                  toDate +
                  '&sellerId=' +
                  appUser.sellerId._id
                "
                class="downlink"
              >
                <span
                  style="
                    font-size: 14px;
                    font-family: poppinssemibold;
                    color: #fff;
                  "
                >
                <v-icon>mdi-download-outline</v-icon> PDF</span
                >
              </a>
            </v-btn>
          </v-flex>
          <v-flex xs12 sm6 md2 lg2 xl2 pa-2>
            <v-btn color="#30B868">
              <a
                target="_blank"
                :href="
                  ipURL +
                  '/order/itemWise/excel?fromDate=' +
                  fromDate +
                  '&toDate=' +
                  toDate +
                  '&sellerId=' +
                  appUser.sellerId._id
                "
                class="downlink"
              >
                <span
                  style="
                    font-size: 14px;
                    font-family: poppinssemibold;
                    color: #fff;
                  "
                >
                <v-icon>mdi-download-outline</v-icon> Excel</span
                >
              </a>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4 v-for="(item, i) in orders" :key="i">
      <!-- <v-flex xs12 sm11 text-left pb-4 pl-2>
            <span
              style="font-family: opensansbold; font-size: 20px; color: #808080"
            >
              {{ formatDate(item._id.create_date) }}
            </span>
          </v-flex> -->
      <v-layout wrap justify-center>
        <v-flex xs12 sm11>
          <v-card>
            <v-layout wrap justify-star py-3 px-5>
              <!-- <v-flex xs12 py-2>
                  <v-layout wrap justify-space-between>
                    <v-flex text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Total Amount
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span style="color: #000000; font-family: poppinsmedium"
                            >{{ item.totalAmountSum }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex text-right>
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            > Total Quantity
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="color: #30b868; font-family: poppinsmedium"
                            >{{ item.quantity }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-divider></v-divider>
                </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 xl12 py-2 text-start>
                <v-layout wrap>
                  <!-- <v-flex xs1>
                      <v-img
                        :src="mediaUURL + transaction.productId.photos[0]"
                        height="100px"
                        contain
                        :alt="transaction.name"
                      >
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-img>
                    </v-flex> -->
                  <v-flex xs9>
                    <v-layout wrap justify-center fill-height>
                      <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                        <v-layout
                          wrap
                          justify-start
                          style="line-height: 16px"
                          fill-height
                          v-if="item._id"
                        >
                          <v-flex xs12>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #191919;
                              "
                            >
                              {{ item._id.productItemName }}
                              <!-- <span v-if="list.name.length > 25"> ... </span> -->
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-3 v-if="item._id">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Size
                              <span style="font-family: poppinsregular"
                                >: {{ item._id.size }}</span
                              >
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-1>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Price
                              <span style="font-family: poppinsregular"
                                >: ₹ {{ item._id.offerPrice }}
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 sm6 text-left>
                <v-layout wrap justify-end>
                  <v-flex xs12 pb-4>
                    <span
                      style="
                        color: #000000;
                        font-family: poppinsmedium;
                        font-size: 14px;
                      "
                      >Total Amount :
                    </span>
                    <span style="color: #30b868; font-family: poppinsmedium"
                      >₹ {{ item.totalAmountSum }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 text-right>
                <v-layout wrap justify-end>
                  <v-flex xs12 pb-4>
                    <span
                      style="
                        color: #000000;
                        font-family: poppinsmedium;
                        font-size: 14px;
                      "
                      >Total Quantity :
                    </span>
                    <span style="color: #30b868; font-family: poppinsmedium">{{
                      item.quantity
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5 v-if="orders.length < 1">
      <v-flex xs12 text-center>
        <span
          style="font-family: poppinsregular; font-size: 25px; color: #000000"
        >
          Oops! No Data Found
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
      <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      menu1: false,
      menu2: false,
      fromDate: new Date(),
      toDate: new Date(),
      seller: "All",
      day: "last 7 days",
      days: [
        "last 7 days",
        "last 30 days",
        "last 3 months",
        "last 6 months",
        "last year",
        "Custom",
      ],
      orderStatus: "",
      orders: [],
      purchase: [],
      shop: [],
      sellerId: "",
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    //   fromDate() {
    //     this.getData();
    //   },
    //   toDate() {
    //     this.getData();
    //   }
  },
  beforeMount() {
    if (this.fromDate || this.toDate) {
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
      this.toDate = this.toDate.toISOString().substr(0, 10);
      this.getData();
    }
    //if (this.toDate) {
    //this.toDate = this.toDate.toISOString().substr(0, 10);
    //this.getData();
    //}
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/order/item/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          sellerId: this.appUser.sellerId._id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.orders = response.data.data;
            //   this.purchase = response.data.summary;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
  },
};
</script>